/**
 * Contenitore dei dati dell'applicazione
 */

module BgacademyApplicationData {
    let app = angular.module("app");

    app.factory('BgacademyApplicationData', ["$translate", ($translate: angular.translate.ITranslateService) => {
        let BgacademyApplicationData = {

		/**
		 * Costanti relative all'applicazione
		 */
		constants: {
            
            RANDOM_SURVEY_LENGTH: "RANDOM_SURVEY_LENGTH",
            // Numero di persone da mostrare per ogni "paginata"
            NETWORK_SEARCH_SIZE: 5,
            // Numero mimino di feedback da ricevere per considerare l'area chiusa
            MIN_NUMBER_OF_FEEDBACK: 5,

            LOGGED_USER_NOT_FOUND: "LOGGED_USER_NOT_FOUND",
            USER_NOT_AUTHORIZED: "USER_NOT_AUTHORIZED",
            USER_WITHOUT_AUTHS: "USER_WITHOUT_AUTHS",

            USER_LIST_ADMIN: "USER_LIST_ADMIN",

            BGACADEMY_ACK_APPLICATION_TOUR: "BGACADEMY_ACK_APPLICATION_TOUR",

            BGACADEMY_TUTORIAL_PDF: "BGLAB_TUTORIAL",

            USER_NOT_FOUND: "USER_NOT_FOUND",

            ALL: "ALL",

            DEVPATH: "DEVPATH",
            TRAININGPLATFORM: "TRAININGPLATFORM",
            LIBRARY: "LIBRARY",
            BGACADEMY: "BGACADEMY",
            COURSEMANAGER: "COURSEMANAGER",

            TEACHER_CONTACT: "TEACHER_CONTACT",

            FAQ: "FAQ",

            COURSE_CARD: "COURSE_CARD", // Scheda corso
            INFORMATIONS: "INFORMATIONS", // Informazioni
            MATERIALS: "MATERIALS", // Materiali

            COURSE_SUPPLIER: "COURSE_SUPPLIER",
            TEACHING_ROLE: "TEACHING_ROLE",
            TUTOR_ROLE: "TUTOR_ROLE",

            BGACADEMY_MANAGE_ANNOUNCEMENT: "BGACADEMY_MANAGE_ANNOUNCEMENT",
            BGACADEMY_MANAGE_COURSES: "BGACADEMY_MANAGE_COURSES",
            BGACADEMY_MANAGE_NOTE: "BGACADEMY_MANAGE_NOTE",
            BGACADEMY_MANAGE_QUESTION: "BGACADEMY_MANAGE_QUESTION",
            BGACADEMY_MANAGE_SUCCESS_STORY: "BGACADEMY_MANAGE_SUCCESS_STORY",
            BGACADEMY_MANAGE_TEMPLATE: "BGACADEMY_MANAGE_TEMPLATE",

            BGACADEMY_USE_COURSE: "BGACADEMY_USE_COURSE",
            BGACADEMY_USE_WEBINAR: "BGACADEMY_USE_WEBINAR",
            BGACADEMY_MANAGE_WEBINAR: "BGACADEMY_MANAGE_WEBINAR",
            BGACADEMY_WEBINAR_TEACHER: "BGACADEMY_WEBINAR_TEACHER",

            NOT_PASSED: "NOT_PASSED",
            PASSED: "PASSED",
            
            COURSE_DATE_LINK: "COURSE_DATE_LINK",

            // Scaffale
            SHELF: "SHELF",
            // Quantità
            QUANTITY: "QUANTITY",
            // COLLOCAZIONE
            COLLOCATION: "COLLOCATION",

            ASKED: "ASKED",
            CONFIRMED: "CONFIRMED",
            ABORTED: "ABORTED",
            REFUSED: "REFUSED",
            RETURNED: "RETURNED",
            EXTENDED: "EXTENDED",
            LATE_RETURN: "LATE_RETURN",
            LATE_CONFIRM: "LATE_CONFIRM",
            QUEUED_ASK: "QUEUED_ASK",
            RETIRED: "RETIRED",
            // Import status
            "COMPLETE": "COMPLETE",
            "ERROR": "ERROR",
            "ATTACHMENT": "ATTACHMENT",
            "DAM_ATTACHMENT": "DAM_ATTACHMENT",

            // Tipo di apertura
            "EXTERNAL_OBJECT_OPEN_MODE": "EXTERNAL_OBJECT_OPEN_MODE",
            "NEW_WINDOW": "NEW_WINDOW",
            "FRAMESET": "FRAMESET",

            NEWS: "NEWS",

            "AVAIABLE": "AVAIABLE",
            "NOT_AVAIABLE": "NOT_AVAIABLE",

            SCORM_CONSUMED: "SCORM_CONSUMED",

            "SURVEY": "SURVEY",

            // Object types
            COURSE: "COURSE",
            ONLINE: "ONLINE",

            TRAINER_TYPE: "TRAINER_TYPE",
            TUTOR_TYPE: "TUTOR_TYPE",

            CANNOT_SEND_INVITE: "CANNOT_SEND_INVITE",
            TEMPLATE_NOT_FOUND: "TEMPLATE_NOT_FOUND",

            EXTERNAL_SUPPLIER: "EXTERNAL_SUPPLIER",
            INTERNAL_STAFF: "INTERNAL_STAFF",

            PUBLISHED: "PUBLISHED",

            MANAGERIAL: "MANAGERIAL",
            NORMATIVE: "NORMATIVE",
            TECHNICAL_COMMERCIAL: "TECHNICAL_COMMERCIAL",

            // Course Attribute Credit Type
            IVASS_TYPE: "IVASS_TYPE",
            EFA_A_TYPE: "EFA_A_TYPE",
            EFA_B_TYPE: "EFA_B_TYPE",
            EFP_TYPE: "EFP_TYPE",
            EXTRA_TYPE: "EXTRA_TYPE",
            
            // URL remoto alla piattaforma esterna dov'è gestito il corso
            REMOTE_PLATFORM_COURSE_LINK: "REMOTE_PLATFORM_COURSE_LINK",
            // L'oggetto richiede che sia fatto un SSO verso SEP
            REQUIRE_SEP_SSO_TOKEN: "REQUIRE_SEP_SSO_TOKEN",


            IVASS_TYPE_CREDITS_REFERENCE_YEAR: "IVASS_CREDITS_REFERENCE_YEAR",
            EFA_A_TYPE_CREDITS_REFERENCE_YEAR: "EFA_A_CREDITS_REFERENCE_YEAR",
            EFA_B_TYPE_CREDITS_REFERENCE_YEAR: "EFA_B_CREDITS_REFERENCE_YEAR",
            EFP_TYPE_CREDITS_REFERENCE_YEAR: "EFP_CREDITS_REFERENCE_YEAR",
            EXTRA_TYPE_CREDITS_REFERENCE_YEAR: "EXTRA_CREDITS_REFERENCE_YEAR",
            MIFID_II_TYPE_CREDITS_REFERENCE_YEAR: "MIFID_II_CREDITS_REFERENCE_YEAR",

            IS_SURVEY_NOT_REPEATABLE: "IS_SURVEY_NOT_REPEATABLE",

            NONE: "NONE",

            IVASS: "IVASS",
            EFA_A: "EFA A",
            EFA_B: "EFA B",
            EFP: "EFP",
            EXTRA: "EXTRA",

            COURSE_CARD_TYPE: "COURSE_CARD",
            MATERIALS_TYPE: "MATERIALS",
            INFORMATIONS_TYPE: "INFORMATIONS",

            LIBRARY_ITEM: "LIBRARY_ITEM",

            VALID: "VALID",

            VALIDATED_SURVEY: "VALIDATED_SURVEY",

            COURSE_DATE: "COURSE_DATE",

            // Nuovo upload
            EVENT_NEW_UPLOAD: "EVENT_NEW_UPLOAD",
            
            // Oggetto Atomico di base (solo in piattaforma senza dipendenze esterne)
            ITEM: "ITEM",
            // Oggetto contenitore di oggetti atomici (es: sezioni di progetti, slideshow, ecc)
            ITEMS_COLLECTION: "ITEMS_COLLECTION",
            // Oggetto contenitore collezioni (es: progetti)
            CONTAINER: "CONTAINER",
            // Oggetto Scorm/TinCan liberamente utilizzabile (crea una registration al primo accesso)
            SCORM_FREE: "SCORM_FREE",
            // Oggetto Scorm/TinCan utilizzabile solo su invito (va creata una registration prima manualmente)
            SCORM_INVITE: "SCORM_INVITE",
            // Oggetto sul DAM
            DAM_ITEM: "DAM_ITEM",

            // Tipi di attributi dell'item
            // Link esterno
            LINK: "LINK",
            // Id dell'oggetto collegato (es: al DAM o al motore SCORM esterno)
            EXTERNAL_OBJECT_ID: "EXTERNAL_OBJECT_ID",
            EXTERNAL_OBJECT_SINGLETON: "EXTERNAL_OBJECT_SINGLETON",
            // Valore dell'oggetto (es: ore formative)
            VALUE: "VALUE",
            // Autore del contenuto
            AUTHOR: "AUTHOR",
            // Editore del contenuto
            EDITOR: "EDITOR",
            // Anno di pubblicazione
            PUBLISH_YEAR: "PUBLISH_YEAR",
            // Luogo di pubblicazione
            PUBLISH_LOCATION: "PUBLISH_LOCATION",
            // Paese di pubblicazione
            PUBLISH_COUNTRY: "PUBLISH_COUNTRY",
            // ISBN del libro
            ISBN: "ISBN",
            // Anno di edizione
            EDITION_YEAR: "EDITION_YEAR",
            // Regia
            DIRECTOR: "DIRECTOR",
            // Elenco di tags relativi a Conoscenze Tech
            TECH_COMPETENCES: "TECH_COMPETENCES",
            // Elenco di tags relativi a Conoscenze Soft
            SOFT_COMPETENCES: "SOFT_COMPETENCES",
            // Elenco di tags relativi a Argomento
            ARGUMENTS: "ARGUMENTS",
            // True se l'oggetto è di tipo tecnico
            TECHNICAL_SKILL: "TECHNICAL_SKILL",
            // True se l'oggetto è di tipo soft
            SOFT_SKILL: "SOFT_SKILL",
            // Tipologia di oggetto (video, audio, testo, ecc)
            OBJECT_TYPE: "OBJECT_TYPE",
            // Elenco di lingue in cui è disponibile il contenuto (es: video in inglese e in italiano, libro solo in inglese)
            LANG: "LANG",
            // Ratio del video collegato (e.g.: "16/9", "3/4")
            VIDEO_ASPECT_RATIO: "VIDEO_ASPECT_RATIO",

            // Suggerimento di un contenuto
            SUGGESTION: "SUGGESTION",
            // Visibilità generica
            VISIBILITY: "VISIBILITY",

            // Pagine dell'applicativo (costanti che servono come attributo ad alcune direttive per individuare la pagina corrente)
            TRAINING_PLAN: "TRAINING_PLAN",
            HISTORY: "HISTORY",

            // Object types
            MAGAZINES: "MAGAZINES",
            MAGAZINE: "MAGAZINE",
            BOOKS: "BOOKS",
            BOOK: "BOOK",
            LEARNING_PLAN: "LEARNING_PLAN",
            PLAN: "PLAN",
            PROJECT: "PROJECT",
            SECTION: "SECTION",
            PODCAST: "PODCAST",
            GRAPHS: "GRAPHS",
            GRAPH: "GRAPH",
            EBOOK: "EBOOK",
            ELEARNING: "ELEARNING",
            VIDEO: "VIDEO",
            DOCUMENT: "DOCUMENT",
            SCORM: "SCORM",
            MULTIMEDIA: "MULTIMEDIA",
            RENTABLE: "RENTABLE",
            OTHER: "OTHER",
            IMAGE: "IMAGE",
            AUDIO: "AUDIO",
            WORK: "WORK",

            SUCCESS_STORY_ATTACHMENT: "SUCCESS_STORY_ATTACHMENT",
            SUCCESS_STORY_VIDEO: "SUCCESS_STORY_VIDEO",

            BGACADEMY_SUPERVISION_COURSES: "BGACADEMY_SUPERVISION_COURSES",
            BGACADEMY_SUPERVISION_TRAININGS: "BGACADEMY_SUPERVISION_TRAININGS",
            BGACADEMY_SUPERVISION_USERS: "BGACADEMY_SUPERVISION_USERS",
            BGACADEMY_SUPERVISION_GROUPS: "BGACADEMY_SUPERVISION_GROUPS",
            BGACADEMY_SUPERVISION_ANNOUNCEMENTS: "BGACADEMY_SUPERVISION_ANNOUNCEMENTS",
            BGACADEMY_SUPERVISION_DASHBOARD: "BGACADEMY_SUPERVISION_DASHBOARD",
            BGACADEMY_SUPERVISION_TEMPLATES: "BGACADEMY_SUPERVISION_TEMPLATES",
            BGACADEMY_SUPERVISION_ITEMS: "BGACADEMY_SUPERVISION_ITEMS",
            BGACADEMY_SEE_LIBRARY: "BGACADEMY_SEE_LIBRARY",

            STARTED: "STARTED",

            COURSE_DATE_TEMPLATE: "COURSE_DATE_TEMPLATE",
            CONFIRM_SUBSCRIPTION_MAIL: "CONFIRM_SUBSCRIPTION_MAIL",
            TEXT_TEMPLATE: "TEXT_TEMPLATE",
            COURSE_USER_INVITE_MAIL: "COURSE_USER_INVITE_MAIL",
            REFUSE_SUBSCRIPTION_MAIL: "REFUSE_SUBSCRIPTION_MAIL",
            INVITE_SOLICIT_MAIL: "INVITE_SOLICIT_MAIL",
            REMINDER_MAIL: "REMINDER_MAIL",
            SAVE_THE_DATE: "SAVE_THE_DATE",
            EXPIRATION_WARNING: "EXPIRATION_WARNING",

            INVITE_SOLICIT_MAIL_TEMPLATE_ID: "COURSE_USER_INVITE_SOLICIT_MAIL",
            REMINDER_MAIL_TEMPLATE_ID: "COURSE_USER_REMINDER_MAIL",
            INVITE_SOLICIT_ONLINE_MAIL_TEMPLATE_ID: "COURSE_USER_INVITE_ONLINE_SOLICIT_MAIL",
            REMINDER_ONLINE_MAIL_TEMPLATE_ID: "COURSE_USER_ONLINE_REMINDER_MAIL",

            DVD: "DVD",

            PRESENCE: "PRESENCE",

            COURSE_CATEGORY: "COURSE_CATEGORY",
            WEBINAR_CATEGORY: "WEBINAR_CATEGORY",
            TAG: "TAG",

            SYSTEM_CREATION: "**SYSTEM**",

            SUPPLIER_PEOPLE_TYPE: "SUPPLIER_PEOPLE_TYPE",
            USER_TYPE: "USER_TYPE",

            OVERNIGHT: "OVERNIGHT",
            DINNER_DATE_START: "DINNER_DATE_START",
            OVERNIGHT_DATE_START: "OVERNIGHT_DATE_START",
            OVERNIGHT_DATE_END: "OVERNIGHT_DATE_END",
            TRANSPORT: "TRANSPORT",
            DINNER: "DINNER",
            NOTE: "NOTE",
            DINNER_DATE_END: "DINNER_DATE_END",

            DRAFT: "DRAFT",

            // Item eliminato
            EVENT_ITEM_DELETED: "EVENT_ITEM_DELETED",
            EVENT_ITEM_DELETED_TINCAN_VERB: "deleted",
            // Item modificato
            EVENT_ITEM_UPDATED: "EVENT_ITEM_UPDATED",
            EVENT_ITEM_UPDATED_TINCAN_VERB: "updated",
            // Tag eliminato
            EVENT_TAG_DELETED: "EVENT_TAG_DELETED",
            EVENT_TAG_DELETED_TINCAN_VERB: "deleted",
            // Tag modificato
            EVENT_TAG_UPDATED: "EVENT_TAG_UPDATED",
            EVENT_TAG_UPDATED_TINCAN_VERB: "updated",
            // L'utente ha fatto una ricerca
            EVENT_SEARCH: "EVENT_SEARCH",
            EVENT_SEARCH_TINCAN_VERB: "searched",
           // L'utente ha aperto l'oggetto
            EVENT_ITEM_OPEN: "EVENT_ITEM_OPEN",
            EVENT_ITEM_OPEN_TINCAN_VERB: "opened",
           // L'utente ha iniziato l'oggetto
            EVENT_ITEM_STARTED: "EVENT_ITEM_STARTED",
            EVENT_ITEM_STARTED_TINCAN_VERB: "started",
            // L'utente ha completato l'oggetto
            EVENT_ITEM_CONSUMED: "EVENT_ITEM_CONSUMED",
            EVENT_ITEM_CONSUMED_TINCAN_VERB: "consumed",
            // L'utente ha certificato l'oggetto
            EVENT_ITEM_CERTIFIED: "EVENT_ITEM_CERTIFIED",
            EVENT_ITEM_CERTIFIED_TINCAN_VERB: "qualified",
            // L'utente ha sugerito l'oggetto
            EVENT_ITEM_SUGGESTED: "EVENT_ITEM_SUGGESTED",
            EVENT_ITEM_SUGGESTED_TINCAN_VERB: "shared",
            THEMATIC_AREA: "THEMATIC_AREA",
            THEMATIC_AREA_MODULE: "THEMATIC_AREA_MODULE",

            EXTRA_COURSE_NAME: "EXTRA_COURSE_NAME",

            EXTRA_COURSE: "EXTRA_COURSE",

            // ChiaveSesso dell'utente loggato
            "MALE": "M",
            "FEMALE": "F",

            "USER_UPLOAD": "USER_UPLOAD",

            // Card cover
            "CARD_COVER": {
                "ITEM_TYPE": "CARD_COVER",
                "MAX_HEIGHT": 98,
                "MAX_WIDTH": 280,
                "MAX_WEIGHT_KB": 50
            },

            "COURSE_TYPE_COURSE": "COURSE",
            "COURSE_TYPE_ONLINE": "ONLINE",
            "COURSE_TYPE_EXTRA_COURSE": "EXTRA_COURSE",

            "COURSE_USER_STATUS_INSERTED": "INS",
            "COURSE_USER_STATUS_INVITED": "INVITED",
            "COURSE_USER_STATUS_CONFIRMED": "CONF",
            "COURSE_USER_STATUS_CANCELLED": "CANCELLED",
            "COURSE_USER_STATUS_PRESENT": "PRESENT",
            "COURSE_USER_STATUS_ABSENT": "ABSENT",

            "COURSE_STATUS_DRAFT" : "DRAFT",
            "COURSE_STATUS_CONFIRMED" : "CONFIRMED",
            "COURSE_STATUS_CLOSED": "CLOSED",
            "COURSE_STATUS_PAUSED": "PAUSED",
            "COURSE_STATUS_CANCELLED": "CANCELLED",

            "TRAINING_STATUS_DRAFT" : "DRAFT",
            "TRAINING_STATUS_CONFIRMED" : "CONFIRMED",
            "TRAINING_STATUS_CLOSED": "CLOSED",
            "TRAINING_STATUS_PAUSED": "PAUSED",
            "TRAINING_STATUS_CANCELLED": "CANCELLED",

            "COURSE_DATE_SEAT_TYPE_PRESENCE": "PRESENCE",
            "COURSE_DATE_SEAT_TYPE_WEBINAR": "WEBINAR",

            "announcementAttributeType": {
                "IMAGE_TYPE": "IMAGE_TYPE",
                "LINK": "LINK",
            },

            "announcementStatus": {
                "DRAFT": "DRAFT",
                "STATUS_PUBLISHED": "STATUS_PUBLISHED"
            },

            "trainingStatus": {
                "DRAFT": "DRAFT",
                "STATUS_PUBLISHED": "STATUS_PUBLISHED"
            },

            AWARD_SOFT_COMPETENCES: "SOFT_COMPETENCES",
            AWARD_TECH_COMPETENCES: "TECH_COMPETENCES",

            DASHBOARD_FILTER_COMPANIES: "COMPANIES",
            DASHBOARD_FILTER_COMPANY_DIVISIONS: "COMPANY_DIVISIONS",
            DASHBOARD_FILTER_DIVISION_MANAGERS: "DIVISION_MANAGERS",
            DASHBOARD_FILTER_MANAGER_PEOPLE: "MANAGER_PEOPLE",
            DASHBOARD_FILTER_USER: "USER",

            LIKE_VOTE: "VOTE",
            LIKE_SIGN: "+",
            DISLIKE_SIGN: "-",

            EVENT_SCORM_COMPLETED: "EVENT_SCORM_COMPLETED",

            SCORM_REFRESH_PAGE_DONE: "SCORM_REFRESH_PAGE_DONE",

            NETWORK_FP: "Divisione FP",
            NETWORK_PB: "Divisione PB",
            NETWORK_RM: "Divisione RM",

            SUPPLIER_TYPE: "SUPPLIER_TYPE",

            EVENT_COURSE_USER_INVITED: "EVENT_COURSE_USER_INVITED",
            EVENT_COURSE_USER_INVITE_ACCEPTED: "EVENT_COURSE_USER_INVITE_ACCEPTED",
            EVENT_COURSE_USER_INVITE_REFUSED: "EVENT_COURSE_USER_INVITE_REFUSED",
            EVENT_COURSE_USER_PRESENT: "EVENT_COURSE_USER_PRESENT",
            EVENT_COURSE_USER_ABSENT: "EVENT_COURSE_USER_ABSENT",
            EVENT_COURSE_DATE_CERTIFIED: "EVENT_COURSE_DATE_CERTIFIED",
            EVENT_COURSE_DATE_CERTIFICATION_DOWNLOADED: "EVENT_COURSE_DATE_CERTIFICATION_DOWNLOADED",
            
            // Numero masso di MB degli upload
            UPLOAD_MAX_SIZE_MB: 1000,

            BG_ANALYTICS_COMPANY: "Banca Generali",

            SURVEY_IMAGE: "SURVEY_IMAGE",

            NEW_SATISFACTION_QUESTIONNAIRE: "NEW_SATISFACTION_QUESTIONNAIRE",

            sectionMonitor: {
                MODULE_NAMES: {
                    COURSE_DATE: "COURSE_DATE",
                    ANNOUNCEMENT: "ANNOUNCEMENT"
                },
                EVENT_NAMES: {
                    EVENT_COURSE_DATE_OPEN: "EVENT_COURSE_DATE_OPEN",
                    EVENT_FAQ_LIST_OPEN: "EVENT_FAQ_LIST_OPEN",
                    EVENT_SUCCESS_STORY_LIST_OPEN: "EVENT_SUCCESS_STORY_LIST_OPEN",
                    EVENT_ANNOUNCEMENT_OPEN: "EVENT_ANNOUNCEMENT_OPEN"
                }
            },

            NO_MORE_PLACES: "NO_MORE_PLACES",

            PERCENTAGE: "PERCENTAGE",
            // Giorni passati massimi entro cui considerare un Item nuovo
            DAYS_TO_CONSIDER_NEW_ITEM: 14,

            LOGIN_REPORT: "LOGIN_REPORT",
            LIBRARY_ITEMS_REPORT: "LIBRARY_ITEMS_REPORT",
            IVASS_BIENNIUM_REPORT: "IVASS_BIENNIUM_REPORT",
            COMPLETION_MIFID_2_REPORT: "COMPLETION_MIFID_2_REPORT",
            MATERIALS_ATTACHMENTS_ORDER: "MATERIALS_ATTACHMENTS_ORDER",

            //ITEM
            ITEM_TYPE_WEBINAR :"WEBINAR",
            ITEM_ATTRIBUTE_TYPE_TEACHER: "TEACHER",
            ITEM_ATTRIBUTE_TYPE_TUTOR: "TUTOR",
            ITEM_ATTRIBUTE_TYPE_START_TIME: "START_TIME",
            ITEM_ATTRIBUTE_TYPE_END_TIME: "END_TIME",
            ITEM_TAKER_TYPE_CONSUMER: "CONSUMER",
            ITEM_TAKER_TYPE_ASSISTANT: "ASSISTANT",



            MIFID_2_VALIDITY: "MIFID_2_VALIDITY",
            MIFID_II_TYPE: "MIFID_II_TYPE",

            GENERIC_MAIL: "GENERIC_MAIL",
            INVITATION_MAIL: "INVITATION_MAIL",
            GENERIC_NOTIFICATION: "GENERIC_NOTIFICATION"
            
        },


		/**
		 * Metodo che ritorna l'array con la lista di tipologie di oggetti
		*/
        getItemTypesList:() => {
            let l = [
              //  { code: BgacademyApplicationData.constants.MAGAZINE, desc: <string>null},
              //  { code: BgacademyApplicationData.constants.BOOK, desc: <string>null},
                { code: BgacademyApplicationData.constants.LEARNING_PLAN, desc: <string>null},
                { code: BgacademyApplicationData.constants.PROJECT, desc: <string>null},
             //   { code: BgacademyApplicationData.constants.SECTION, desc: <string>null},
                { code: BgacademyApplicationData.constants.DOCUMENT, desc: <string>null},
                { code: BgacademyApplicationData.constants.PODCAST, desc: <string>null},
             //   { code: BgacademyApplicationData.constants.GRAPH, desc: <string>null},
             //   { code: BgacademyApplicationData.constants.EBOOK, desc: <string>null},
                { code: BgacademyApplicationData.constants.ELEARNING, desc: <string>null},
                { code: BgacademyApplicationData.constants.IMAGE, desc: <string>null},  
             //   { code: BgacademyApplicationData.constants.DVD, desc: <string>null},
                { code: BgacademyApplicationData.constants.VIDEO, desc: <string>null}
            ];
            for (let i = 0; i < l.length; i++) {
                l[i].desc = $translate.instant("item.types." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati del corso esistenti e le relative descrizioni in lingua
		 */
        getCourseStatusesList: function() {
        let l = [
            { code: BgacademyApplicationData.constants.COURSE_STATUS_DRAFT, desc: <string>null},
            { code: BgacademyApplicationData.constants.COURSE_STATUS_CONFIRMED, desc: <string>null},
            { code: BgacademyApplicationData.constants.COURSE_STATUS_CLOSED, desc: <string>null},
            { code: BgacademyApplicationData.constants.COURSE_STATUS_PAUSED, desc: <string>null},
            { code: BgacademyApplicationData.constants.COURSE_STATUS_CANCELLED, desc: <string>null}
        ];
        for (let i=0; i< l.length; i++) {
            l[i].desc = $translate.instant("course.statuses." + l[i].code);
        }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati del percorso esistenti e le relative descrizioni in lingua
		 */
        getTrainingStatusesList: function() {
        let l = [
            { code: BgacademyApplicationData.constants.TRAINING_STATUS_DRAFT, desc: <string>null},
            { code: BgacademyApplicationData.constants.TRAINING_STATUS_CONFIRMED, desc: <string>null},
            { code: BgacademyApplicationData.constants.TRAINING_STATUS_CLOSED, desc: <string>null},
            { code: BgacademyApplicationData.constants.TRAINING_STATUS_PAUSED, desc: <string>null},
            { code: BgacademyApplicationData.constants.TRAINING_STATUS_CANCELLED, desc: <string>null}
        ];
        for (let i=0; i< l.length; i++) {
            l[i].desc = $translate.instant("training.statuses." + l[i].code);
        }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati degli utenti del corso esistenti e le relative descrizioni in lingua
		 */
        getCourseUserStatusesList: function() {
            let l = [
                { code: BgacademyApplicationData.constants.COURSE_USER_STATUS_INSERTED, desc: <string>null},
                { code: BgacademyApplicationData.constants.COURSE_USER_STATUS_INVITED, desc: <string>null},
                { code: BgacademyApplicationData.constants.COURSE_USER_STATUS_CONFIRMED, desc: <string>null},
                { code: BgacademyApplicationData.constants.COURSE_USER_STATUS_CANCELLED, desc: <string>null},
                { code: BgacademyApplicationData.constants.COURSE_USER_STATUS_PRESENT, desc: <string>null},
                { code: BgacademyApplicationData.constants.COURSE_USER_STATUS_ABSENT, desc: <string>null}
            ];
            for (let i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("course.userStatus." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con i tipi di presenza alle date dei corsi e le relative descrizioni in lingua
		 */
        getCourseDateSeatTypesList: function() {
            let l = [
                { code: BgacademyApplicationData.constants.COURSE_DATE_SEAT_TYPE_PRESENCE, desc: <string>null},
                { code: BgacademyApplicationData.constants.COURSE_DATE_SEAT_TYPE_WEBINAR, desc: <string>null}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("courseDate.seatType." + l[i].code);
            }
			return l;
		},
        

        /**
         * Metodo che ritorna l'array con i tipi corso
         */
        getCourseTypesList: function() {
            let l = [
                { code: this.constants.COURSE_TYPE_COURSE, desc: <string>null},
                { code: this.constants.COURSE_TYPE_ONLINE, desc: <string>null}
            ];
            for (let i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("course.types." + l[i].code);
            }
            return l;
        },

    };
	return BgacademyApplicationData;
    }])
};